<template>
  <div id="maindiv">
    <div class="container">
      <b-navbar shadow>
        <template slot="brand">
          <b-navbar-item tag="router-link" :to="{ path: '/' }">
            <img alt="Vue logo" src="./assets/logo.png" height="50px" />
          </b-navbar-item>
        </template>
        <template slot="start">
          <b-navbar-item v-show="false" tag="router-link" :to="{ path: '/' }"
            >Home</b-navbar-item
          >

          <b-navbar-item
            v-show="false"
            tag="router-link"
            :to="{ path: '/about' }"
            >About</b-navbar-item
          >

          <b-navbar-item
            v-show="false"
            tag="router-link"
            :to="{ path: '/secure' }"
            ><span><i class="fa fa-lock"></i> Secure</span></b-navbar-item
          >

          <b-navbar-item
            v-show="isAllowed(['DIEdit', 'DIView'])"
            tag="router-link"
            :to="{ path: '/data-integrity' }"
            ><span
              ><i class="far fa-check-circle"></i> Data Integrity</span
            ></b-navbar-item
          >

          <b-navbar-item
            v-show="isAllowed(['CLEdit', 'CLView', 'CLMasterData', 'LPTEdit'])"
            tag="router-link"
            :to="{ path: '/caplink/shipment-dashboard' }"
            ><span><i class="fa fa-archway"></i> Caplink</span></b-navbar-item
          >

          <b-navbar-dropdown
            hoverable
            v-show="
              isAllowed([
                'LMEdit',
                'LMView',
                'MDEdit',
                'MDView',
                'LDEdit',
                'LDView',
                'RMEdit',
                'RMView',
                'CTEdit',
                'CTView',
                'BST-View',
                'IAT-View',
              ])
            "
          >
            <template slot="label">
              <span><i class="fa fa-stream"></i> Lane Intel</span>
            </template>
            <b-navbar-item
              v-show="isAllowed(['LMEdit', 'LMView'])"
              tag="router-link"
              :to="{ path: '/records' }"
              >Tariffs &amp; Contracts</b-navbar-item
            >
            <b-navbar-item
              v-show="isAllowed(['MDEdit', 'MDView'])"
              tag="router-link"
              :to="{ path: '/organizations' }"
              >Organizations</b-navbar-item
            >
            <!-- <i class="fa fa-building"></i> FA for orgs-->
            <b-navbar-item
              v-show="isAllowed(['LDEdit', 'LDView'])"
              tag="router-link"
              :to="{ path: '/zones' }"
              >Zones</b-navbar-item
            >
            <!-- <i class="fa fa-globe"></i> FA for zones-->
            <b-navbar-item
              v-show="isAllowed(['LDEdit', 'LDView'])"
              tag="router-link"
              :to="{ path: '/unlocos' }"
              >Unlocos</b-navbar-item
            >
            <b-navbar-item
              v-show="isAllowed(['BST-View'])"
              tag="router-link"
              :to="{ path: '/rating/billing-summary-dashboard' }"
              >Billing Summary Tool</b-navbar-item
            >
            <b-navbar-item
              v-show="isAllowed(['BST-View','IAT-View'])"
              tag="router-link"
              :to="{ path: '/rating/billing-summary-tool-settings' }"
              >Billing Summary Settings</b-navbar-item
            >
            <b-navbar-item
              v-show="isAllowed(['CTEdit', 'CTView'])"
              tag="router-link"
              :to="{ path: '/carrier-intel/carrier-intel-management' }"
              >Carrier Intel Search</b-navbar-item
            >
            <b-navbar-item
              v-show="isAllowed(['LMEdit', 'LMView'])"
              tag="router-link"
              :to="{ path: '/co2-emissions' }"
              >CO2 Emissions Tool</b-navbar-item
            >
          </b-navbar-dropdown>

          <b-navbar-dropdown
            hoverable
            v-show="isAllowed(['ACCT:BSD', 'ACCT:OAP', 'ACCT:ARU','ACCT:EUTA','ACCT:DIG','IAT-View'])"
          >
            <template slot="label">
              <span><i class="fas fa-calculator"></i> Accounting</span>
            </template>
            <b-navbar-item
              v-show="isAllowed(['ACCT:OAP'])"
              tag="router-link"
              :to="{ path: '/ap-vendor-invoice' }"
            >
              AP Vendor Invoice
            </b-navbar-item>
            <b-navbar-item
              v-show="isAllowed(['ACCT:BSD'])"
              tag="router-link"
              :to="{ path: '/billing-summary-data' }"
            >
              Billing Summary Data
            </b-navbar-item>
            <b-navbar-item
              v-show="isAllowed(['ACCT:ARU'])"
              tag="router-link"
              :to="{ path: '/ar-upload' }"
            >
              AR Upload
            </b-navbar-item>
            <b-navbar-item
              v-show="isAllowed(['ACCT:EUTA'])"
              tag="router-link"
              :to="{ path: '/eu-trucking/euro-trucking-cw-invoice-audit' }"
              >EU Trucking Audit Tool</b-navbar-item
            >
            <b-navbar-item
              v-show="isAllowed(['ACCT:DIG'])"
              tag="router-link"
              :to="{ path: '/cw-digest' }"
              >CW Billing Digest Tool</b-navbar-item
            >
            <b-navbar-item
              v-show="isAllowed(['IAT-View'])"
              tag="router-link"
              :to="{ path: '/accounting/intracompany-accrual-dashboard' }"
              >Intracompany Accrual Tool</b-navbar-item
            >
          </b-navbar-dropdown>

          <b-navbar-dropdown
            hoverable
            v-show="isAllowed(['DOCS:E&A', 'DOCS:MAN'])"
          >
            <template slot="label">
              <span><i class="fas fa-clipboard"></i> Reports</span>
            </template>
            <b-navbar-item
              v-show="isAllowed(['DOCS:E&A'])"
              tag="router-link"
              :to="{ path: '/e-a-report' }"
            >
              E & A Report
            </b-navbar-item>
            <b-navbar-item
              v-show="isAllowed(['DOCS:MAN'])"
              tag="router-link"
              :to="{ path: '/manifests' }"
            >
              Consol Manifests
            </b-navbar-item>
          </b-navbar-dropdown>

          <b-navbar-dropdown
            hoverable
            v-show="isAllowed(['EDIBookings', 'EDIRecon'])"
          >
            <template slot="label">
              <span><i class="fas fa-clipboard"></i> EDI</span>
            </template>
            <b-navbar-item
              v-show="isAllowed(['EDIBookings'])"
              tag="router-link"
              :to="{ path: '/edi/bookings-dashboard' }"
            >
              3PL Bookings
            </b-navbar-item>
            <b-navbar-item
              v-show="isAllowed(['EDIRecon'])"
              tag="router-link"
              :to="{ path: '/edi/recon-dashboard' }"
            >
              Recon
            </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-item
          v-show="isAllowed(['QUAL-View'])"
            tag="router-link"
            :to="{ path: '/quality/quality-dashboard' }"
            ><span><i class="fa fa-check-square"></i> Quality</span></b-navbar-item
          >
        </template>
        <template slot="end">
          <b-navbar-item
            v-show="!isLoggedIn"
            tag="router-link"
            :to="{ path: '/login' }"
            ><span><i class="fa fa-sign-in"></i> Login</span></b-navbar-item
          >

          <b-switch
            v-show="isLoggedIn"
            type="is-info"
            v-bind:value="$store.state.showDocs"
            @input="$store.commit('change_docs_visibility')"
            >Show Docs |</b-switch
          >

          <b-navbar-dropdown hoverable v-show="isAllowed(['Admin', 'DIAdmin', 'UserAdmin','CO2:Edit'])">
            <template slot="label">
              <span><i class="fa fa-users"></i> Admin</span>
            </template>
            <b-navbar-item
              v-show="$store.getters.userIsAdmin || $store.getters.userIsUserAdmin"
              tag="router-link"
              :to="{ path: '/admin' }"
            >
              User Roles &amp; Routes
            </b-navbar-item>
            <b-navbar-item
              v-show="isAllowed(['DIAdmin'])"
              tag="router-link"
              :to="{ path: '/validator-management' }"
            >
              DI - Validator Mgmt
            </b-navbar-item>
            <b-navbar-item
              v-show="isAllowed(['Admin'])"
              tag="router-link"
              :to="{ path: '/bulk-editing' }"
            >
              Bulk Editing
            </b-navbar-item>
            <b-navbar-item
              v-show="isAllowed(['Admin'])"
              tag="router-link"
              :to="{ path: '/testing' }"
            >
              Testing
            </b-navbar-item>
            <b-navbar-item
              v-show="isAllowed(['PROG:View','PROG:Edit'])"
              tag="router-link"
              :to="{ path: '/program-editing' }"
            >
              Program Editing
            </b-navbar-item>
            <b-navbar-item
              v-show="isAllowed(['CO2:Edit'])" 
              tag="router-link"
              :to="{ path: '/CO2-Filters' }"
            >
              CO2 Filters
            </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-item
            v-show="isLoggedIn"
            tag="router-link"
            :to="{ path: '/profile' }"
            ><span
              ><i class="fa fa-user-circle"></i> Profile</span
            ></b-navbar-item
          >

          <b-navbar-item v-show="isLoggedIn">
            <a href="#" @click="logout"
              ><span><i class="fa fa-sign-out-alt"></i> Logout</span></a
            >
          </b-navbar-item>
        </template>
      </b-navbar>
    </div>
    <br />

    <div class="container is-fluid">
      <div class="columns is-centered">
        <router-view></router-view>
      </div>
    </div>
    <div class="container mt-6">
      <div class="columns">
        <div class="column mt-6">
          <sidebar-menu> </sidebar-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SidebarMenu from "@/components/SidebarMenu";
  export default {
    data() {
      return {};
    },
    components: {
      SidebarMenu,
    },
    computed: {
      isLoggedIn: function() {
        return this.$store.getters.isLoggedIn;
      },
    },
    methods: {
      logout: function() {
        this.$store.dispatch("logout").then(() => {
          this.$router.push("/login");
        });
      },
      initialize: function() {
        if (
          this.$route.path != "/login" &&
          this.$route.path != "/set-password"
        ) {
          this.$store.dispatch("checkToken").catch(() => {
            if (this.$route.path != "/login") {
              // alert(this.$route.path);
              this.$router.push("/login");
            }
          });
        }
      },
      isAllowed: function(roles) {
        return this.$store.getters.userHasAllowedRole(roles);
      },
    },
    mounted() {
      // if (!this.$store.getters.isLoggedIn){
      //   this.$store.dispatch('refresh').catch(()=>{this.$store.dispatch('logout')})
      // }
    },
    created: async function() {
      await this.$store.dispatch("setPrograms");
      //this.$store.dispatch('checkToken').catch(()=>this.$router.push('login'))
      //alert('App created')
      // this.initialize();
      // this.$store.dispatch("getUser");
    },
    // created: function() {
    //   // this.$store.dispatch('getUser').catch(()=>this.$router.push('login'))
    //   this.initialize();
    //   this.$store.dispatch("getUser").catch(()=> this.$router.push('/login'))
    // }
  };
</script>

<style lang="scss">
  .p-1 {
    padding: 1em;
  }
  .nomargin {
    margin-bottom: 0 !important;
  }
  @import "~bulma/sass/utilities/_all";
  $primary: #ef7622;
  $primary-invert: findColorInvert($primary);
  $twitter: #4099ff;
  $twitter-invert: findColorInvert($twitter);
  $fullhd: 1800px;
  // Setup $colors to use as bulma classes (e.g. 'is-twitter')
  $colors: (
    "white": (
      $white,
      $black,
    ),
    "black": (
      $black,
      $white,
    ),
    "light": (
      $light,
      $light-invert,
    ),
    "dark": (
      $dark,
      $dark-invert,
    ),
    "primary": (
      $primary,
      $primary-invert,
    ),
    "info": (
      $info,
      $info-invert,
    ),
    "success": (
      $success,
      $success-invert,
    ),
    "warning": (
      $warning,
      $warning-invert,
    ),
    "danger": (
      $danger,
      $danger-invert,
    ),
    "twitter": (
      $twitter,
      $twitter-invert,
    ),
  );
  // $link: $primary;
  // $link-invert: $primary-invert;
  // $link-focus-border: $primary;
  @import "~bulma";
  @import "~buefy/src/scss/buefy";
</style>